<template>
  <div>
    <data-sync-state v-model="dataSynchronized" data='générales' @refresh="refresh"></data-sync-state>

      <b-row>
        <b-col md=5>
            <form-field caption="Type">
              <b-form-input readonly v-model="typeName"/>
            </form-field>
        </b-col>
        <b-col md=5>
          <form-field caption="Echelon">
            <b-form-input readonly v-model="scopeLabel" />
          </form-field>
        </b-col>
        <b-col md=2>
          <form-field caption="Année/saison">
            <b-form-input readonly v-model="year" />
          </form-field>
        </b-col>
      </b-row>
      <form-field v-if="scope == 'region'">
        <template #caption>
          <b-alert variant="secondary" :show="scope == 'region'">
            <i>Comptant pour le Chapionnat de France {{ currentCompetition.SEASON.yearSelectiveCdF }}</i>
          </b-alert>
        </template>
      </form-field>
            <form-field caption="Nom de la compétition" :required="canChangeName" >
              <b-form-input type="text" v-model="edtName" @change="name_change" :readonly="!canChangeName" :required="canChangeName"/>
            </form-field>
            <form-field caption="Emplacement">
              <b-form-input type="text" v-model="edtLocation" class="location" @change="location_change" :readonly="!canChangeLocation" />
            </form-field>


            <slot name="levels"></slot>
      <fieldset class="fieldset">
        <legend>Options</legend>
      <b-row>
        <b-col>
            <checkbox-form-field
              caption='Est "open"'
              @change="isOpen_change"
              switch
            >
              <template #info-helper-placeholder>
                <info-helper>
                  Lorsque cette case est cochée, la compétition est ouverte à des compétiteurs "non-fédéraux", potentiellement étrangers, ou dont la licence n'est pas à jour.<br/>
                  Le cas échéant, les résultats de ces compétiteurs pour cette compétition ne seront pas pris en compte dans un quelconque classement officiel FFVL.
                </info-helper>
              </template>
            </checkbox-form-field>
        </b-col>

        <b-col v-if="userHasRole(Roles.Tester)">
            <checkbox-form-field
              disabled
               caption="Est pour simulation uniquement"
               v-model="isForSimulationPurposeOnly"
               v-if="userHasRole(Roles.Tester)"
               switch
            >
            </checkbox-form-field>
        </b-col>
      </b-row>
      </fieldset>

      <fieldset class="fieldset" v-if="canChangeSettings">
        <competition-admins :competition_id="competition_id">
        </competition-admins>
      </fieldset>
  </div>
</template>

<script>
import DataSyncState from '@/components/Utilities/DataSyncState.vue';
import FormField from '@/components/Panels/FormField.vue';
import CheckboxFormField from '@/components/Panels/CheckboxFormField.vue';

import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import { RST_COMPETITION/*RST_ROUND,*/ /* RST_ROUND_COMPULSORY, RST_EVENT, *//* RST_COMPETITOR_NOTES, RST_COMPETITOR_COMPULSORY_NOTE,*//* RST_CATEGORY, */} from "@/LocalDB";
import InfoHelper from '@/components/InfoHelper.vue';
import CompetitionAdmins from "../CompetitionAdmins.vue"

export default{
  model:{
    prop: 'competition_id',
  },
  props:{
    competition_id: { type:String, required:true },
    canChangeName: { type:Boolean, default: true },
    canChangeLocation: { type:Boolean, default: true},
  },
  components: { DataSyncState, FormField, CheckboxFormField, InfoHelper, CompetitionAdmins },
  data(){
    return {
      VERBOSE_MODE: true,

      dataSynchronized: null,
      currentCompetition: null,
   };
  },
  computed:{
    typeName(){
      var ret = this.currentCompetition && this.currentCompetition.COMPETITION_TYPE ? this.currentCompetition.COMPETITION_TYPE.name : '';
      return ret;
    },
    scope(){
      var ret = this.currentCompetition ? this.currentCompetition.scope : '';
      return ret;
    },
    scopeLabel(){
      var ret = this.currentCompetition && this.currentCompetition.SCOPE ? this.currentCompetition.SCOPE.name : '';
      if(this.currentCompetition)
      {
        if(this.currentCompetition.scope == 'region')
          ret += ' - Manche(s) sélective';
        if(this.currentCompetition.scope == 'nation')
          ret += ' - Finale';
      }
      return ret;
    },
    year(){
      return this.currentCompetition ? this.currentCompetition.year : '';
    },
    isForSimulationPurposeOnly(){
      return this.currentCompetition ? this.currentCompetition.isForSimulationPurposeOnly : false;
    },
    edtName(){
      return this.currentCompetition ? this.currentCompetition.name : '';
    },
    edtLocation(){
      return this.currentCompetition ? this.currentCompetition.location : '';
    },
    edtIsOpen(){
      return this.currentCompetition ? this.currentCompetition.isOpen : false;
    },
  },
  methods:{
    async name_change(newValue){
      await this.$showSaver();
      CompetitionsConfigurationHelper.renameCompetition(this.competition_id, newValue);
      this.currentCompetition.name = newValue;
      this.toastSaved('Compétition renommée.')
      this.$hideSaver();
    },
    async location_change(newValue){
      await this.$showSaver();
      CompetitionsConfigurationHelper.changeCompetitionLocation(this.competition_id, newValue);
      this.currentCompetition.location = newValue;
      this.toastSaved('Lieu de la compétition modifié.')
      this.$hideSaver();
    },
    async isOpen_change(newValue){
      await this.$showSaver();
      CompetitionsConfigurationHelper.changeIsOpenFlag(this.competition_id, newValue);
      this.toastSaved('Etat "open" modifié.');
      this.$hideSaver();
    },

    async refresh(){
      await this.$showRefresher();
      this.dataSynchronized = await CompetitionsConfigurationHelper.refreshGeneralData(this.competition_id);
      this.currentCompetition = RST_COMPETITION.query().where("id", this.competition_id).first();
      this.$hideRefresher();
    }
  },
  async mounted(){
    console.log('GeneralTabs mounted');
    await this.refresh();
  }
}
</script>
